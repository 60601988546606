@font-face {font-family: jua; src: url("./font/BMJUA_ttf.ttf");}
* {
  margin: 0;
  padding: 0;
  font-family: jua;
}
.Main_css {
  font-family: sans-serif;
  text-align: center;
  background-color: lemonchiffon;
  padding-bottom: calc(100px + 10px);
}
.Header_css {
  background-color: skyblue;
}
header {
  width: 100%;
  height: auto;
  background-color: #8f8ebf;
  border: none;
  z-index: 9999;
  padding: 10px;
}
#Login_p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0; 
  background-color: lemonchiffon;
}
object {
  width: 60%;
  height: 720px;
  border: 1px;
}
.header_li {
  list-style: none;
  display: flex;
  padding: 0;
}
.header_li a{
  color:black;
  text-decoration: none;
  display: flex;
  padding: 0;
}
.li_itemL {
  margin: 10px;
}
.li_itemR {
  margin-left: auto;
  margin-right: 10px;
}

#login_F{

}
footer {
  position: fixed; /* 고정 위치 */
  left: 0;
  bottom: 0; 
  width: 100%; 
  height: 50px; 
  background-color: #8f8ebf; 
  color: black; 
  text-align: center; 
  padding: 10px 0; 
  font-size: 14px; 
}
button{
  border: 0;
  background-color: transparent;
  font-size: 18px;
}